/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var Z63 = (function (parent, $) {

	    var MediaQueryListener = function() {
	        this.afterElement = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;
	        this.currentBreakpoint = '';
	        this.lastBreakpoint = '';
	        this.init();
	    };

	    MediaQueryListener.prototype = {

	        init: function () {
	            var self = this;

	            if(!self.afterElement) {
	                return;
	            }

	            self._resizeListener();

	        },
	        _resizeListener: function () {
	            var self = this;

	            $(window).on('resize orientationchange load', function() {
	                // Regexp for removing quotes added by various browsers
	                self.currentBreakpoint = self.afterElement.getPropertyValue('content').replace(/^["']|["']$/g, '');

	                if (self.currentBreakpoint !== self.lastBreakpoint) {
	                    $(window).trigger('breakpoint-change', self.currentBreakpoint);
	                    self.lastBreakpoint = self.currentBreakpoint;
	                }

	            });
	        }

	    };

	    parent.mediaqueryListener = parent.mediaqueryListener || new MediaQueryListener();

	    return parent;

	}(Z63 || {}, jQuery));

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var inMinute = new Date(new Date().getTime() + 60 * 60 * 24 * 10 * 1000); //2592000000
        var pageMenuTop = 0;

        // Calculate --vh unit to better match 100vh heights on mobile devices with addres bars and toolbars changing viewport height
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        function appHeight() {
          document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
        }
        appHeight();


        // Wrap iframes in responsive div. ONLY inside container blocks l-content div
        /*
        'iframe[src*="youtube.com"]',
        'iframe[src*="vimeo.com"]',
        'iframe[data-src*="youtube.com"]',
        'iframe[data-src*="vimeo.com"]'
        */
        var players = [
          'iframe[src*="youtube.com"]',
          'iframe[data-src*="youtube.com"]'
        ];
        var fitVids = document.querySelectorAll(players.join(","));

        if (fitVids.length) {
          // Loop through videos
          for (let video of fitVids) {
            // Get Video Information
            var width = video.getAttribute("width");
            var height = video.getAttribute("height");
            var aspectRatio = height / width;
            var parentDiv = video.parentNode;

            // Wrap it in a DIV
            var div = document.createElement("div");
            div.className = "responsive-wrapper";
            div.style.paddingBottom = aspectRatio * 100 + "%";
            parentDiv.insertBefore(div, video);
            video.remove();
            div.appendChild(video);
            /*video.parentElement.classList.add('responsive-wrapper');
            video.parentElement.style.paddingBottom = aspectRatio * 100 + "%";*/

            // Clear height/width from video
            video.removeAttribute("height");
            video.removeAttribute("width");
          }
        }


      	var getUrlParameter = function getUrlParameter(sParam) {
      	    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      	        sURLVariables = sPageURL.split('&'),
      	        sParameterName,
      	        i;

      	    for (i = 0; i < sURLVariables.length; i++) {
      	        sParameterName = sURLVariables[i].split('=');

      	        if (sParameterName[0] === sParam) {
      	            return sParameterName[1] === undefined ? true : sParameterName[1];
      	        }
      	    }
      	};

        $(document).on('click', '.wpcf7-submit', function(e){
    			if( $('.ajax-loader').css('visibility') == 'visible' ) {
              e.preventDefault();
              return false;
           }
        });





        // JavaScript to be fired on all pages TODO
        if ( $('body').hasClass('dev') ) {
      		window['ga-disable-UA-2407578-13'] = true;
      	}


        /* in page links */
        $('a[href*="#"]:not([href="#"]):not([href="#contactform"]):not(".js-noinpage")').on('click touchend', function() {
    			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    				var target = $(this.hash);
    				var menuoffset = $('.main-nav').height();
    				if ( $(this).parent().parent().parent().hasClass('page-menu') && $(window).width() > 768 ) {
    					menuoffset += $(this).parent().parent().parent().height();
    				} else {
    					menuoffset = 0;
    				}
    				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    				if (target.length) {
    					$('html,body').animate({
    					  scrollTop: target.offset().top - menuoffset
    					}, 1000);
    					return false;
    				}
    			}
    		});


        // goto selected link from select input field
        $('.link-select').on('change', function() {
          var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          if(!pattern.test($(this).val())) {
            return false;
          } else {
            window.location.href = $(this).val();
            //window.open($(this).val());
            return true;
          }
        });






        // add icons to specific link types


        // add triangle arrow svg to all content links. Remove later for specific link types
        //$('.section-content a:not(.button)').append('<svg class="icon-link"><use xlink:href="#play_arrow"></use></svg>');
        $('.section-content a:not(.button):not(.link-external)').append('<span class="linkarrow"> &#8250;</span>');

        // pdf
        var pdfLinks = $('.section-content a[href$=".pdf"]:not(.link--clean):not(.link-clean)');
        //pdfLinks.find('svg').remove();
        pdfLinks.find('.linkarrow').remove();
        pdfLinks.addClass('button button--download button--primary button--inline');
        //pdfLinks.prepend('<svg class="icon-pdf"><use xlink:href="#pdf"></use></svg>');
        pdfLinks.prepend('<svg><use xlink:href="#download"></use></svg>');

        // mp3
        var mp3Links = $('.section-content a[href$=".mp3"]');
        //mp3Links.find('svg').remove();
        mp3Links.find('.linkarrow').remove();
        mp3Links.append('<svg class="icon-audio"><use xlink:href="#audio"></use></svg>');

        // add video play svg to all youtube links
        $('a:not(.link--clean):not(.link-clean)').each(function(){
          var _videoUrl = $(this).attr('href');
          if ( _videoUrl ) {
            var matches_yt = _videoUrl.match(/watch\?v=([a-zA-Z0-9\-_]+)/);
            var matches_vimeo = _videoUrl.match(/vimeo.com\/([a-zA-Z0-9\-_]+)/);
            if (matches_yt || matches_vimeo) {
              $(this).addClass('js-movie-yt button button--video button--primary button--inline');
              //$(this).find('svg').remove();
              $(this).find('.linkarrow').remove();
              //$(this).prepend('<svg class="icon-play"><use xlink:href="#play_video"></use></svg>');
              $(this).prepend('<svg><use xlink:href="#play_arrow"></use></svg>');
            }
          }
        });


        // remove not needed svg
        $('a').has('img').find('.linkarrow').remove();
        $('.m3-team a').find('.linkarrow').remove();
        $('.breadcrumbs a').find('.linkarrow').remove();
        $('.link--clean, .link-clean').find('.linkarrow').remove();
        $('.link--clean, .link-clean').find('.icon-play').remove();
        $('.link--clean, .link-clean').find('.icon-audio').remove();

        // add img class to all image links
        //$( 'a' ).has( 'img' ).addClass( 'link--img' );

        // open youtube videos
        $('.movie, .js-movie-yt').magnificPopup({
      		type: 'iframe',
      		iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                  '<div class="mfp-close"></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'/*,
      			patterns: {
      				youtube: {
      	                index: 'youtube.com/',
      	                id: 'v=',
      	                src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1&showinfo=0'
      	            }
      			}*/
      		}
      	});
        $('.js-movie-vimeo').magnificPopup({
      		type: 'iframe',
      		iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                  '<div class="mfp-close"></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'/*,
      			patterns: {
      				youtube: {
      	                index: 'youtube.com/',
      	                id: 'v=',
      	                src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1&showinfo=0'
      	            }
      			}*/
      		}
      	});
        $('.js-open-image').magnificPopup({type:'image'});
        $('.js-open-inline').magnificPopup({type:'inline'});

        $('.movie, .js-movie-yt, .js-movie-vimeo').on('click', function(e) {
          e.preventDefault();
        });

        // slider init
        $('.l-row--slider').each(function() {
          let sliderAutoplaySpeed = 0;
          let sliderAutoplay = false;
          let sliderSpeed = 500;
          let sliderEffect = 'slide';
          let sliderFade = false;
          let sliderArrows = true;
          let sliderArrowsEl = $(this);
          let sliderBullets = true;
          let sliderDotsEl = $(this);

          sliderAutoplaySpeed = $(this).attr('data-slider-autoplay-speed');
          sliderAutoplay = $(this).attr('data-slider-autoplay');
          if ( sliderAutoplay == 1 ) {
            sliderAutoplay = true;
          } else {
            sliderAutoplay = false;
          }

          sliderSpeed = $(this).attr('data-slider-speed');
          sliderEffect = $(this).attr('data-slider-effect');
          sliderFade = false;
          if ( sliderEffect == 'fade' ) {
            sliderFade = true;
          }
          sliderArrows = $(this).attr('data-slider-arrows');
          if ( sliderArrows == 1 ) {
            sliderArrows = true;
          } else {
            sliderArrows = false;
          }
          sliderBullets = $(this).attr('data-slider-bullets');
          if ( sliderBullets == 1 ) {
            sliderBullets = true;
          } else {
            sliderBullets = false;
          }
          $(this).slick({
            draggable: false,
            arrows: sliderArrows,
            appendArrows: sliderArrowsEl,
            dots: sliderBullets,
            appendDots: sliderDotsEl,
            infinite: true,
            mobileFirst: true,
            slidesToScroll: 1,
            fade: sliderFade,
            adaptiveHeight: false,
            speed: sliderSpeed,
            autoplay: sliderAutoplay,
            autoplaySpeed: sliderAutoplaySpeed,
            prevArrow: '<a href="#" class="slick-prev"><svg class=""><use xlink:href="#arrow-right" /></svg></a>',
            nextArrow: '<a href="#" class="slick-next"><svg class=""><use xlink:href="#arrow-right" /></svg></a>',
          });
        });
        

        $('.l-section-container--slider > .slider--blog').slick({
          draggable: false,
          arrows: true,
          infinite: true,
          mobileFirst: true,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
          prevArrow: '<a href="#" class="slick-prev"><svg class=""><use xlink:href="#arrow-right" /></svg></a>',
          nextArrow: '<a href="#" class="slick-next"><svg class=""><use xlink:href="#arrow-right" /></svg></a>',
        });



        $(window).trigger('resize').trigger('scroll');

        $('.menu-login').on('click ', function() {
    			ga('send', 'event', 'login', 'clicked');
    		});



        // open social sharing window
        $('.social-window').click(function (event) {
    			event.preventDefault();
    			window.open($(this).attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");
    		});



        $('[data-tabs-button="0"]').addClass('active');
        $('[data-tabs-content="0"]').addClass('visible');
        $('.tabs__sidecontent[data-tabs-content="0"]').addClass('animated-in');
        
        /*$('.tabs--accordion-changing-side [data-tabs-content="0"]').css('height', 'auto');
        $('.tabs--accordion-fixed-side [data-tabs-content="0"]').css('height', 'auto');*/

        //$('.tabs__button').parent().parent().parent().find('.tabs__content').removeClass('visible');
        //$('.tabs__button').parent().parent().parent().find('[data-tabs-content="'+$('.tabs__button.active').attr('data-tabs-button')+'"]').addClass('visible');

        $('.tabs__button').on('click', function() {
          var thisGroup = '.' + $(this).attr('data-tabs-group');
          $(thisGroup).find('.tabs__content').removeClass('visible');
          $(this).parent().find('.tabs__button').removeClass('active');
          $(this).addClass('active');
          $(thisGroup).find('[data-tabs-content="'+$(this).attr('data-tabs-button')+'"]').addClass('visible');

          // Horizontal
          //if ( $(this).parent().parent().parent().hasClass('tabs--horizontal') ) {
            $(thisGroup).find('.tabs__container').css('height', $(thisGroup).find('[data-tabs-content="'+$(this).attr('data-tabs-button')+'"]').height());
            $(thisGroup).find('.tab-indicator').css('left', $(this).position().left).css('width', $(this).outerWidth());
          //}
          // Change mobile select value
          $(thisGroup).find('.tabs__select').val( $(this).attr('data-tabs-button') );
        });
        function tabIndicatorSizes() {
          if ( $('.tab-indicator').length ) {
            $('.tab-indicator').each(function() {
               $(this).css('left', $(this).parent().find('.tabs__button.active').position().left).css('width', $(this).parent().find('.tabs__button.active').outerWidth());
            });
          }

        }
        tabIndicatorSizes();




        $('.tabs__accordion-open').on('click', function() {
          var thisGroup = '.' + $(this).attr('data-tabs-group');
          var thisTabContent = $(thisGroup).find('.tabs__content[data-tabs-content="'+$(this).attr('data-tabs-button')+'"]');
          var thisTabSideContent = $(thisGroup).find('.tabs__sidecontent[data-tabs-content="'+$(this).attr('data-tabs-button')+'"]');

          // Close all and remove active
          $(thisGroup).find('.tabs__content').removeClass('visible');
          $(thisGroup).find('.tabs__content').css('height', 0);

          // Set all previous and next accordions closed
          $(this).prevAll().removeClass('active');
          $(this).nextAll().removeClass('active');

          // Open or close clicked accordion
          $(this).toggleClass('active');

          // Side content animate in/out
          $(thisGroup).find('.tabs__sidecontent').removeClass('animated-in');
          setTimeout(function(){
            $(thisGroup).find('.tabs__sidecontent').removeClass('visible');
          }, 300);

          // Open only if clicked accordion is active
          if ( $(this).hasClass('active') ) {
            thisTabContent.addClass('visible');
            thisTabContent.css('height', thisTabContent.attr('data-height'));
            setTimeout(function(){
              thisTabSideContent.addClass('visible');
              thisTabSideContent.addClass('animated-in');
            }, 301);
          }

        });

        $('.tabs__select').on('change', function() {
          var thisGroup = '.' + $(this).attr('data-tabs-group');
          $(thisGroup).find('.tabs__content').removeClass('visible');
          $(thisGroup).find('[data-tabs-content="'+$(this).val()+'"]').addClass('visible');

          // Change desktop select value only for horizontal
          if ( $(thisGroup).find('.tabs__button').length ) {
            $(thisGroup).find('.tabs__button[data-tabs-button="' + $(this).val() + '"]').click();

          }

        });

        function accordionSizes() {
          $('.tabs--accordion-changing-side .tabs__content').each(function() {
            $(this).attr('data-height', $(this).find('.l-row').height() + 'px');
          });
          $('.tabs--accordion-fixed-side .tabs__content').each(function() {
            $(this).attr('data-height', $(this).find('.l-row').height() + 'px');
          });
        }
        accordionSizes();
        // Set first items height
        $('.tabs--accordion-changing-side [data-tabs-content="0"]').each(function() {
          $(this).css('height', $(this).attr('data-height'));
        });
        $('.tabs--accordion-fixed-side [data-tabs-content="0"]').each(function() {
          $(this).css('height', $(this).attr('data-height'));
        });

        $(window).on('breakpoint-change', function(e, breakpoint) {
          
      		if ( breakpoint === 'break-4' || breakpoint === 'break-5' ) {
            $('body').removeClass('lockscroll');
            $('body').removeClass('nav-open');
            if ( document.querySelector('.js-toggle-nav') ) {
              document.querySelector('.js-toggle-nav').setAttribute('aria-expanded', 'false');
            }
            
      		}

      	});

        // parallax effect
        $(window).scroll(function () {

          window.requestAnimationFrame(function() {

            $('.l-section-container--parallax .parallax-bg').css({
              'transform':    'translate3d(' + '0px, ' + $(window).scrollTop() / 2 + 'px, 0)'
            });
          });
        });

        debounce = function(func, wait, immediate) {
      	    var timeout;
      	    return function() {
      	        var context = this, args = arguments;
      	        clearTimeout(timeout);
      	        timeout = setTimeout(function() {
      	            timeout = null;
      	            if (!immediate) func.apply(context, args);
      	        }, wait);
      	        if (immediate && !timeout) func.apply(context, args);
      	    };
      	};

        

        // debounced resize event
      	var resizeDebounce = debounce(function() {
          appHeight();

          accordionSizes();
          tabIndicatorSizes();
      	}, 100);
      	window.addEventListener('resize', resizeDebounce);







      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
